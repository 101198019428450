import "style.css";
import "tailwindcss/lib/css/preflight.css";
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from "components/footers/SimpleFiveColumn.js";

import Home from "pages/Home.js";
import Years from "pages/Years.js";

// @TODO: Centre time and date on hero // done??
// @TODO: Fix entre firstname text too big on mobile //done??
// @TODO: Fix yes/maybe/no too big on mobile

function App() {
  return (
    <BrowserRouter>
      <AnimationRevealPage>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/years" element={<Years />} />
      </Routes>
      </AnimationRevealPage>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
