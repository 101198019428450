import React from "react";
import tw from "twin.macro";

import LogoImage from "../../images/tg24.png";

const Container = tw.div`relative bg-gray-200`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} style={{ width: '90px', height: 'auto' }} />
          </LogoContainer>
          <CompanyDescription>
            Just for a bit of fun :)
          </CompanyDescription>

        </WideColumn>
        <Column>

        </Column>
        <Column>

        </Column>
        <Column>

        </Column>
      </FiveColumns>
    </Container>
  );
};
