// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

import { getDatabase,ref,set } from "firebase/database";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCe3rwxhsIGeUUpvEJjNyfQ5qZS190Kmf0",
  authDomain: "thanksgliving.firebaseapp.com",
  databaseURL: "https://thanksgliving-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "thanksgliving",
  storageBucket: "thanksgliving.firebasestorage.app",
  messagingSenderId: "1001816929782",
  appId: "1:1001816929782:web:8a457066accb92002f83d7",
  measurementId: "G-8QBJRW4P6L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, ref, set };  // Exporting these so they can be used in other files

