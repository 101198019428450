import React, {useState, useEffect} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { database } from '../../firebase'; // Import Firebase config
import { ref, set, get, update } from 'firebase/database';  // Realtime Database methods

import RSVPName from './RSVPname';
import RSVPForm from './RSVPform';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-10`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-903 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const RSVPContainer = () => {
    const [isRSVPComplete, setIsRSVPComplete] = useState(false);
    const [isNameAmbiguous, setIsNameAmbiguous] = useState(false);
    const [validGuests, setValidGuests] = useState([]);
    const [selectedGuest, setSelectedGuest] = useState(null);
  
    // Fetch guest data when the component mounts
    useEffect(() => {
        const fetchGuests = async () => {
          try {
            const guestsRef = ref(database, 'guests'); // Reference to the 'guests' node in Realtime Database
            const snapshot = await get(guestsRef); // Get data from Realtime Database
            if (snapshot.exists()) {
              const guestList = Object.values(snapshot.val()).map((guest, id) => ({
                id,
                ...guest,
              }));
              setValidGuests(guestList);
            } else {
              console.log('No guests found.');
            }
          } catch (error) {
            console.error('Error fetching guests:', error);
          }
        };
    
        fetchGuests();
      }, []);
  
    const handleNameSubmit = (name) => {
      const matchedGuests = validGuests.filter(guest => guest.name.toLowerCase() === name.toLowerCase());
  
      if (matchedGuests.length === 1) {
        setIsRSVPComplete(true);
        setSelectedGuest(matchedGuests[0]);
      } else if (matchedGuests.length > 1) {
        setIsNameAmbiguous(true);
        setValidGuests(matchedGuests); // Show only ambiguous names
      } else {
        alert('Name not found. Please try again.');
      }
    };
  
    const handleGuestSelect = (guest) => {
      setSelectedGuest(guest);
      setIsNameAmbiguous(false);
      setIsRSVPComplete(true); // Proceed to RSVP form
    };
  
    return (
        <Container>
        <Content>
        {!isRSVPComplete ? (
          <RSVPName
            validGuests={validGuests}
            onNameSubmit={handleNameSubmit}
            isNameAmbiguous={isNameAmbiguous}
            handleGuestSelect={handleGuestSelect}

          />
        ) : (
            <FormContainer>
          <RSVPForm
            selectedGuest={selectedGuest}
          />
          </FormContainer>
        )}
      </Content>
      </Container>
    );
  };
    
export default RSVPContainer;
