// src/pages/Years.js

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation here
import styled, {css} from "styled-components";
import { useMediaQuery, useTheme } from '@mui/material';


import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import tw from "twin.macro";

import tg24 from "images/tg24.png";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-7.svg";
import { Grid } from '@mui/joy';

const DecoratorBlob2 = tw(
    SvgDecoratorBlob2
  )`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;
const DecoratorBlob3 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-901`}
`;

const Title = tw.h4`text-3xl font-bold text-gray-900`;


function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
  
const Years = () => {
    const query = new URLSearchParams(useLocation().search);
    const initialTab = parseInt(query.get('y')-1, 10) || 0; // Default to the first tab if no query param
  
    const [selectedTab, setSelectedTab] = React.useState(initialTab);
  
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

    const LogoContainer = tw.div`flex items-center justify-start md:justify-start mt-0 mb-6`;
    const LogoLink = tw.a`flex items-center`; // Added flex here
    const LogoImg = tw.img`w-8`;
    const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Change orientation for small screens

    return (
      <>
        <LogoContainer>
          <LogoLink href="/">
            <LogoImg src={tg24} style={{ width: '90px', height: 'auto' }} />
            <LogoText>thanksGLiving</LogoText>
          </LogoLink>
        </LogoContainer>
        <DecoratorBlob2 />
        <DecoratorBlob3 />
    
        {/* Media Query to detect mobile view */}
        {(() => {
    
          return (
<Tabs
  aria-label="Yearly tabs"
  orientation={isMobile ? 'horizontal' : 'vertical'}
  value={selectedTab}
  onChange={handleTabChange}
  sx={{
    minWidth: 300,
    height: { xs: 'auto', md: 600 },
    backgroundColor: 'transparent',
  }}
>
  {/* TabList */}
  <TabList tabFlex="auto"
    sx={{
      display: 'flex',
      flexDirection: isMobile ? 'row' : 'column',
      overflowX: isMobile ? 'auto' : 'visible',
    }}
  >
    {tabsData.map((tab, index) => (
      <Tab key={index}>{tab.year}</Tab>
    ))}
  </TabList>

  {/* TabPanel Content */}
  {tabsData.map((tab, index) => (
    <TabPanel key={index} value={index}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Title>{tab.title}</Title>
          <ImageList
            sx={{
              width: { xs: 'auto', md: '100%' },
              height: { xs: 'auto', md: '600px' },
            }}
            variant="masonry"
            gap={8} // Adds spacing between items

            cols={isMobile ? 2 : 6}
            rows={isMobile ? 'auto' : 10}
            rowHeight={80}
          >
            {tab.images.map((item) => (
              <ImageListItem key={item.img}
              cols={isMobile ? 2 : item.cols || 1} // On mobile, enforce two columns per row
              rows={isMobile ? 2.5 : item.rows || 1}>
                <img
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </TabPanel>
  ))}
</Tabs>

          );
        })()}
      </>
    );
    
};

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    cols: 2,
  },
];

const photos23 = [
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczPzIVEYTSYpkUAwo_mGpRdboyJkF6-yTb-yYAEeRcPEtpDmJtPxePmw5pC8pmzFl_1wPm-lWqxZf_3tLsjNo4NR0VQb-xBFzivZZkmsECn_cpRexwhPcfFw43kMVlg13budm9gvOD7bw8HshLjk56gS7g=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNCH40z1PoMUTC5ayoI0hpkqoIARDFLdGzAD4N9tUWXScqp2byPIb1gtdNPUHn9iQ5mfiolxEx-VBBnocFuDrpMIoevbJaXYmIsDj6eZtfwrutnxDXEkVtrqOJaa6o0-OCFZO3yscLiM1oczRjJ4jUwgA=w2638-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczPEHHcQXJhE6rAlrOMpTIO0zlOwWykxwJzwt-6G2YgPvk6UmpK35xLCQdsoEhnrGJpUeb_NqA0uKw1Y9QZNZ_-j9BUiANha-jEdbQlWlQgsKKPs1i9cdh9LOm7JJPrC5oDcG7qokG7NakIuYIVD5UgnZg=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczOISlUKZJ-bGRkQFvYHexVAoqKfKjhN5rw84Gt40L9T7L8j8mtfUnU3ZWzkg9KUMyukrpeSF5ZpVZGXtzlOTfQbalmlZsw_9nH5rRYxpsLVReKYIte_4f_CH0R0gFq-ZTGc8S8YtIjgHuTYksv6YjT5rQ=w1034-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNO3_DuMpkSDT7WJ3nkK-Utbv2r0ztOgqTUXdRui-PlkdqTmLfeWDH1j2SBPxtAzSziPgQrknnUVbdQtQgMSJT_eD4oy2six5AJd8KxrR0TynNnbJOtNuxtxML3KHJ6DOhffRGhFTGGclbf-HE_sA6taA=w1034-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczPqlhajOSGnPPf0RQ7gPeGcuNe-74dd6kAbw8FJ1ULUUrjPoTIxkmdSWqD6qMmTraj-UINKfInPQo5oXgYuToRoh8hWXfOR9Ajyy3E5pUOpvSOEUXzLtbGw--MWvzZO24sHeELoVLskrCU2o0aGkZug5Q=w1034-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNjAeOTBewD2u0EdWFIlxjv1vEJzrXkdSVzf1XiIlulUcXqvhxgVaISsVt7hP2irane_sVcz_3KhepdbidY_W4W61Ymm_DYyeslLllvAa1h5aEDRWGT8LDSivf5cmoCOD322H9TfQczT5pAntwspaplVg=w1034-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNGoPMf_eXTbTH0QvPeOeZUrFJkxgMkbRnKuKaxPMXN9QtNKDBkQaqvmTOnrGKkKH-cWshU6xwg1Skoz7o_9P2Hs9q3GqxUpfZvwCwd2rVaUmPQJ6n1-325Mv6tF9tk4QFAYSvGyXaFJLy45KDR_HtKIA=w1034-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMqrwQffihzOZR9hAhykOJWwsyrWv2NcxhCRzYOOkSf8cOg1TOvUhwLY7xcKxv31Q96dAiig125sDbcmk4bis8Dk1bmUeQzT_AF-gfgA3DqHt7riw8Wn4JmUzNWgCo3QXoy3AXLQkgRIL9rhzs9e3tDBQ=w1034-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczPaVBHSI0zF7cgtYWgaGP6D1iGoh2tphMDnWtowd4P8FmPbcBUf5gi7J4gwXzO4z3qMPZ2-ersNLXjd3_nwfx41e-bDeaWqFtn6xUIL53NjeS420hcls0XPKAR3pcBYlZ8gWyaIpu20eiCWH_UGEfwwXQ=w1034-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczOsWubVAL5rqLK16ua8qNn-RBfOr1d_EKAonn9SCmebtmxl3g_uZzI70TIfvdIwK0cskbqGVxuARgbkojR4QVgywV1wKBeUPAzWpoUxUujFdn6bhbYimezBjNI4uhXBJPnKBc0vSQz6MgXEiwu6FM14QA=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
]

const photos22 = [
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczOtudRDXwOuslaHAvI7mU-FryuU1m74ej0oh5ZDO7NhgooVn83QEZEJAPqZ3ad39S3iz7lQLn-ENEwUsbzP-oxc4KnMXr4tE3YlRVzML63zj1f552GYuO4eFKWgJBYXrTx-JNZjkwWFWMua8ujsLQUKvw=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNhxGY0yhWIzIT22Y9i1STwQvLf3fKWj0A_BDTQROASFpXnkbiT4SoRpgwsuGTnYRvaodPHwPTliLdCMAIsJuOexN-RllaUvtQz9vaVgec3HxTYZLPR4kgsdu6h_ZHO_VmOhGs_H5M7PlLs_Mt5r6eWpQ=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNeEM3mY-iWNLc0QNMLh31EdPUj_lz4zTQ9xSP7xd2eoVZDjsfH5l_4a91_ujlQj1kIfC6-RfV7Cshhf6q70pRWqvT4SbvZCf_5H-YESqRULprO7xXCrlNShka-ehaxLoTu6VKC2VCRUB0-1ZRJYgRQ6Q=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNqCBnDiNm13UKlusjg2v9-YM1B01CwfhSPV3cqd0KY7pnD3vHdAitlWSxp3XzxP32--NiL-nGG_l_fhMEOZ8Ql1SNoUz4qhueihHTuKxwpY0ekCKy6M_CdNOQO-Q9iZYRLvVO8HdUO2PGXdwpFogarrw=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczM_P3xITZjo6XX0kuCeZt1TVhzPxIB2ZYpY5dL2mVWasFUD1AY_3ESaRjmbrBmqV5SqL_aRsVMGGR-ibmqCiM3HAVEcazkI1LBBwREe2Z_L0jYZnSBJS2GMAHCTy-vj6RePaM3GvmS9QJnO_cIc-L4F5Q=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczM_F7NLDI75kBPKXxWKwWL3njYIvNMGROTzNYUA95s-jORE5SEmVtvgE3cIAA8A23OD4d3PJZ0Zm6Mr6EOFYWfnUQgiLsAfyIHgrnVSBhx-4SguSUOAx__53OXg831swg3t5g0Q03qIW8_QWFJr4jGb4g=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMWV7czv6mdeUrg0k_X_r8jMYxSH8_sDAe-tH6pUAEORYBg1wBpyNyrULOhCpvIE-rWafCP3WH13OUinvQ0ZohJB1MFBhks13m81a_cr530A3nxbcDnDXbOI85uRmmn_QLxZaq7l4CtfsyU_2TgXxD8nA=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNbYwyjgq6Ut5XVEKOxXiObdfi8ZUQeZ8IXoBISfwAaE71kSNghftBmQJilqNl2fKcLGddJgZ8GvZcJfSJFBp6P_ciF0OjMPn3oSPH36W45LHmh4sACVEP4jwXp8qaBOviUA_C9VjjHGJGDeYgbkCVbZQ=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNDEy-WHaGGUmFbGigXffADrD2z5mVHgsgWK_ffGKilzSMb22KWQRAqkpAldsNbleeaUIl2LvSpqdhXFHPHXpqQlTJdwTBZ8IprWKQekX6H4A8g64KNQ58TtNMOZtydVIFju4OWo3vFE3_zpRoxEHkHUQ=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNVGDA1dqn7DOej_iGa_x0JJEFLnaGyO_ZnO5pnQh0lkMpOGCfuQzfO5KaoSAWo0ifDr1yAzAbR4wqz77FMGak2oFYIQgtWzCvb8GdeoehWi1g5QweO6yHM3-XfOgRIXg8nzqsMRwoE_zEE1uYcDIR0JQ=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczM7V61PnBvSyXEk3nHpbzFXYL4-qn7jznq7bcT3NEIV8F7L6GhUXpNwx8rbCD_CtjQB-AXjd3TuqObJ1zzV6zL8gocqh3vnJyTd33CsWWLWHO-t0uTMKjwczWXEtvLxxr14Kp6NX_PsrKV96oNQu_d97Q=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNhKazFIfj66y6e8eEjAifaVRC8e8yzVDS6WZ6Yb2qoBQRjOXINc5IrDmL0-rQOVI7Zy2QgfXOKSvSiqMd7vS_iZQK49nvNff-oHFqYKHM3HvtFb6DHgrF27O7h9vZUTfXcoxpA_uQcFX2V6rAhr99xdw=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMrzWwST3fMRt7zAv4myrqwDtBhCvtI2loHd5VTh9Ojf_SaglH-Qg03O2uK-4leNVNS8GT27moryYutxRiOM1gO84f93UK5NpB2CVAeyiyHWLMtBdJBWHHyWpu_IuOQYBs6tQ_ZQSfBpGIHEewj9Zkxnw=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczOSLTFi7S5Ej8P--5bfW-dci_u_BlsAcbBthi2ulL_gTOYSKz6XN88IvTFW3Fg2iVsfl-abtCzW6fYQUt4emNKbpjsBft_s2DKg0jyM4aHbf_U_zxq9sjZM9tyurnbMb0hYN7nNBzfQzx_iKuI8Uhx1PA=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },

];

const photos21 = [
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczPrCkhwIdWTtQwx8jrdMJZ_9iLJ_krH2Dy_IENkpc3uQYJX6uoyAh2OoafxZXSIBWfQl7bTSTUOMPROpe9_toHhp-zuMPJX_F_fMmcgAB-SHze5HbCUjx6guxyAGcrwurybB_z_Dx6n15Hcd_plN9JwOQ=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczN2X0PKfNwP_dkTJfIT8kXwPJxLhzat0QekveSq4_-jb7yTs49MkNdTN-ViU8vB1Ix1EQlMCiey5Xt_HEZQJJtjJSiQwJZnj88EM1z3i1qE69GdQ9Wzs3dlly4CxJtUFlhU0vuY9oIWbeS6RtlH16ABRg=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMTCwhP9XSsFn2hH9TPKLY8GKoVmDm9Ct2H3zwKj0aDGdcwhdwipl199p3_aPVsis8IbBaSbFq54ZzT-pI3knzrkVqI1rFegInPrisAHMyBuHBboU0zDOe4oDFlnd2BYaACYVeaXU528dquTOpXAcDFhw=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },

  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNMVNVmhbV-5VVXNgNsQ9D0QfUXt6Ppbwf-eXPMxqt3k1ounaAwulUn8XSnT1ChrIbylzbyS4ULdxTWI2OKwxvXOmRelZlFmpZ4cul_lRuLKAS9QxfYpDLSCN65U_qoy1vDmIXUKXPNHFvczsqhAR_oqA=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,

  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczOWal9QaIw0m-fUJZ9cVO8l6zc5flYf-xIrEh32EMjPfDmTjOjP5pjOwF2iIHypXnHOmw4aZ-ht2KZ6Yx_Ah6TNI5rG2dHnRp-xDX2u6uw5rAdmpuIouFT582Lih_ZS5I5sji6UTHT0Y4tc-ZPjajqwHQ=w776-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNcrWb9f39LJWk6VcKFzGHjk4O52PQz3aNKoIdT4B7TZwKPHfqFwT8j19Xf8ZUG_MZ53qvQV4ylmLc85WuO6Wv3Fb5l3blRO-h9XP9wd7qTuliZFP2JVeoykFx8j7cLR0eIoiiLhqPG3sfRWzVuldTwLQ=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczM0xdYrR33_diGGhRRXS4lQufeHRilZCl6qZ0S8LRgjOZ66kTiVzjeXeetVLpUPP8IWET5QH2f6pxzDmaKQIgP0-buvfhB7QBtU4lxklxdMfXPEne9YrN9jZFsZ-PNMxYgWUeNS8ZTuxwpm9CYs2ivK3A=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNIz4eClf4DKMrTkcyFMU_O5EEWZbag74uc0WRJGdr25JmNiGWEz5DPe2MGHfvGzwfljdWFoYHbMljSmE6CTbi6qtZX3c8iXuSlB7UtiluAdCCouUglDd4mM163WmXjpqJc1zdHeXSU0g-t7uEbi2jlhg=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMRZK9xv9UWnCWXW9x4wP-7-U6XKMmDpNnFy3hOfxUx_pW4Uw15x_-U66W7FOJHsRYMrn2uaT3Vx4qbn0b68hxGcLzajLUjiBo_a5j_O1iL-iG9flEFahyX2dsfWhdAjf73NOMzGmseLlqXo-_pknkDbA=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMgwBVBZVJPWGs9PCABTe_1Hmx1j1V_Qsl91fXcdw1cetkgzTYANppgBx9nsQ_S9ElpNKIeU4z_YZk-ktpAmH7wCeJWfh0hcO-MXXu4epb7ZindYtBpOmLCArUKrr_tuWHXMZlMO0fyaLi1snGx21vHSQ=w776-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczN_bkbNB_KoarSWkDhhknKDWPA3kHLNFXlvy2-1OsmqFQrj-d9ZkQhUdSbIHN9MAXJI5zu73KlMC-8tJx2HPlVzceR0TiOH1AA_lmFTNHAst0v8_ZkJhmOdjQdlWyTcJUeyNFzzjHpmOnNUT2R8uH0s4Q=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  }
  
];

const photos20 = [
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczPrqES9F2KCTn5bSEjvvfxUyxzUCnrgiF61NsZYUD313qJHc_kb6Aht6px1Q5nemsyRQGpV6DwPcV9avngri-Uh5y22cbuAxReyWBkrhFV14g49EVT5PfVx6KIXsZhKSzNzBAKqmHhALQBPN4EUpOxN3g=w1840-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczOTSFp94qKM4DZAtHXnQmORSXwwKy2OGcvhAOtsZ1makLIlaG8_vQZYkUtOBYD1pv4YFWOk6kNrfsfe_tfFZQH6R-tPftvX9Z3JdTpJ_AjAB62modLwnn2PZYHWc27e19V3H1Bt2npYoaXYoP8bwcVebQ=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMEu-c5thQ1HLCMMYZjolxbo2U7JiHJecoYwTaql-YyrGFJvENePlriyTLCoZ24b5OHitcdZ0WU6T-kZKPdN2LRipghJPWKlRwcuwg42UQR0e13gwWKk8dwlx-AKTsAZ8bkEZDkHNxaIDwst6wtrWwVug=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczOjO2Fnt1cH1_NXbgGBxSbQUFTj8ddtSLTs9Gy7Tk2omSZOB7XNkHHoa5HxN6nPVqkwCKQf10sPeYHU0nbd_8jkh9ACLU3Bja2h749puiFSQcC3tBvJZZK3Q-qkYsxpbIcLzX9WEBcunvRGTtYCmJ5lFg=w1036-h1380-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
]

const photos19 = [
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMk9kL4zVJrnd1Mk5cix2ABasbZBRGIoeqtyyd8Hi2S2nARvOJX5SzgV31OXMUhFcy58IxplR75jWRHijoa_wNULr74a3s_Tv6H2Rja4B_1Qn168phrk9FE3ztTwleij2Y_R-Ji4NgpfnxO-Ui0kYLaFA=w359-h640-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczMDY6YFu2PTTrMGaf18aGbAen9R3BhBxy1FQ9k9D2VwSFQxdZr4qKyG87zXmaXtTub12pSAlkxtcnHM5SN8rXqRVsAjg7JMyTc_XY7I3yOhpST_GY_GlgDXC8Oxo97aOhSuL62PanS8FOJNfjySoJn9rw=w360-h478-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczPz-xwXLhSZsJBQ1VjM8-ATQ5BGjNd_IPwRfmMFZ1Up36hK6A_MPzN6aLUATckx7Exp1XL_jKdw5m2Nk3T0Op6szY_I2OUWtPy0cXt7BSLgsMGV-YtRwCV8o1VUwIEeuSbfzmfXvvI2WQNuiM3nV2DyZA=w360-h478-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczPUM6t5L3m8CPyeO62_yXe7DfvFLz48qRExscnagX7yXQq1llupPebuRpGghYFf-xC7K_tZXVlZM7Ts5Rjz4tI8b1F_d-8ZEfEjWdaMdfSfX2vZ9YDZiKfeCCPfLWr8Q8wIZZVZF7Pqj2IIvSQoSDKLzw=w478-h360-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczNGxnmeK0ZGwPJSxXk8D4lDNdMZ8ENJMKoBlMxJ-I27CR0i5Ing7rxI_G7-pvgVRUeo4Wo7bplVxBXnr6cHft5g0RRQuGLXXS9O5g1qK9jGFx0mDc3ZLyuPkAFUC4UKwtMuAQjZ9eGaAi2gjdHFCnsMdw=w360-h478-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczOwJDg8_h4by7j2FwPQlQzlv5tthMW1-XTh5e2q8hgvqQsVV2OqCMyTVsOjbiKYD1lA8BUNHw4-PQlPYjVZ3ViIPY4oQlWThsbYEYzX2Xw9hJ4ewTuOAxI6HSsiL5FVWrRzMJqHonTk4DdKqYEtQ_15JQ=w359-h476-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczN4P3uxn6C6CKqXZt2u4S4O_k4CJLWqiSlvPVHndEmOROB-a39Bb2V_6_UcypKmxu0NElCLh6c8Tyc3pz0UjYdB3Wl7MlA-v0bkFYkv0Ls1AmUmtJpQKFX4ggrnFnxdAmIQssvd8cv46mGVCbH3WakO9Q=w360-h478-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://lh3.googleusercontent.com/pw/AP1GczN6KdpZMXo2JIgH_vfeIJ2_-uAaw32NS3LbPKCkbDcbgT2L_j_ZXpSnoIkG-qyhGF3Nqexl3W1JrvA_859f8Sd7hsAX4ZBfVoG80uIYnilomIV2ajU8NfrLYvuXXKIA4ELaBCc7O6z1JPodRtW_1Fe1Fw=w360-h478-s-no-gm?authuser=0',
    rows: 2,
    cols: 2,
  },
]

const tabsData = [
  {
    year: "2019",
    title: "Whitehorn, St Andrews",
    description: `The thanksgiving that started it all! Lauren and I began the day cooking in the Wardlaw ground floor kitchen and moved over to the lovely modern Whitehorn kitchen halfway through the day. Lauren and I listened to One Direction all day, which is a tradition that has continued through the years. The bacon-wrapped green beans were a let-down, and a bit of a faff, but the rest of the food was not a bad way to start. I think Andrew maybe concussed himself doing the dishes, but other than that, the day was wonderful and a great start to a yearly tradition!`,
    images: photos19, // Use your image data here
  },
  {
    year: "2020",
    title: "Drumcarrow Rd, St Andrews",
    description: `I’m so glad Lauren and I decided to go through with Thanksgiving despite the global
    pandemic—though my goodness, what an effort it was! Online ordering forms, Tailend takeaway
    boxes, and enough gravy to sink a ship (because we forgot to give it to everyone!). I think Walker
    probably still has nightmares about packing everyone’s orders on the floor of the Drumcarrow living
    room—each slightly different—while racing the clock as the boxes grew increasingly soggy. Despite
    the grand effort, and the not-so-classic Thanksgiving, it was worth keeping the tradition alive. RIP, 2
    Drumcarrow Rd!`,
    images: photos20, // Replace with actual data for 2020
  },
  {
    year: "2021",
    title: "Hepburn Hall, St Andrews",
    description: `This was our first “large” Thanksgiving dinner, and I’m still amazed we managed to fit everyone
around ONE long table in the living room of Hepburn. I made enough mashed potatoes to sink a large
cruise ship, even with Lauren persuading me to save one bag for another day. At the end of the
evening, when Lauren and I were packing all the food into Tupperware, we realized I’d made so much
mash that I hadn’t even gotten close to the bottom of the pot—the potatoes there were still completely
whole! This Thanksgiving also had the added bonus of St Andrews Day Celebrations. Once we were
all stuffed to the brim, we walked into town for the St Andrews Day ceilidh and parade. Lauren and I
ran into the Whey Pat on the way for two shots each, and we ceilidh-ed down South Street! A
wonderful way to end the eve.`,
    images: photos21, // Replace with actual data for 2021
  },
  {
    year: "2022",
    title: "Hepburn Hall, St Andrews",
    description: `The largest Thanksgiving of them all, with around 40 guests (including my childhood best friend Molly, who travelled all the way from Atlanta, Georgia). This Thanksgiving had all the classics, and it was the first year I made a honey-baked ham and added a berry crumble. James and Charlie managed to build a couple of “bonfires” on the table to add to the ambiance, which was followed by a successful quiz made by James.`,
    images: photos22, // Replace with actual data for 2022
  },
  {
    year: "2023",
    title: "Parsons Green, London",
    description: `Not quite as grand as Hepburn, but a success nonetheless. The day started with me wondering why my pies were burning instantly… little did I know the oven was broken, and we’d have to cook the
entire Thanksgiving meal under a grill. I still have no idea how Lauren and I managed to cook an
entire turkey without a functional oven, but somehow we only served dinner a little over 30 minutes
late. The pies were yummy but slightly raw on the bottom due to the oven issues, yet spirits stayed
high! Because our Radipole Road flat is quite petite, I could only invite 12 people, but it was so lovely
to have both old and new friends, from my St Andrews family to friends I met on my MSc course. This
one was chaotic, but the food went down with no complaints!`,
    images: photos23, // Replace with actual
  }
  // Add more years as needed
];

export default Years;
