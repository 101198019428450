import React, { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import { Typography } from "@mui/joy";
import Grid from '@mui/joy/Grid';
import { database } from '../../firebase'; // Import Firebase config
import { ref, set, get, update } from 'firebase/database';  // Realtime Database methods
import { Alert, Snackbar } from '@mui/joy';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import tw from 'twin.macro';

const RSVPButton = tw.button`w-full max-w-xs py-4 px-8 mt-6 bg-primary-500 text-white rounded-full font-bold tracking-wide shadow-lg text-lg transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-orange-600 hocus:-translate-y-px hocus:shadow-xl`;



const RSVPform = ({ selectedGuest }) => {
  const [guests, setGuests] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);  // To manage alert visibility
  const [alertMessage, setAlertMessage] = useState(''); // State for custom alert message


  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const guestsRef = ref(database, 'guests'); // Reference to the 'guests' node in Realtime Database
        const snapshot = await get(guestsRef); // Get data from Realtime Database
        if (snapshot.exists()) {
          const guestList = Object.values(snapshot.val()).map((guest, id) => ({
            id,
            ...guest,
          }));
          setGuests(guestList);
        } else {
          console.log('No guests found.');
        }
      } catch (error) {
        console.error('Error fetching guests:', error);
      }
    };

    fetchGuests();
  }, []);

  const onRSVPChange = async (guestId, newResponse) => {
    try {
      const guestRef = ref(database, `guests/${guestId}`); // Reference to a specific guest's data
      await update(guestRef, {
        response: newResponse, // Update the response for this guest
      });

      // Fetch updated guests from the database
      const guestsRef = ref(database, 'guests');
      const snapshot = await get(guestsRef);
      if (snapshot.exists()) {
        const guestList = Object.values(snapshot.val()).map((guest, id) => ({
          id,
          ...guest,
        }));
        setGuests(guestList); // Update state with the new data from the database
        const updatedGuest = guestList.find(guest => guest.id === guestId);
        setAlertMessage(`${updatedGuest.name}'s RSVP has been updated to ${newResponse}!`);
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error updating response:', error);
    }
  };

 if (!selectedGuest) return <div>Loading...</div>; // Safeguard in case selectedGuest is null


 const managedGuests = guests
    .filter(guest =>
      [selectedGuest.id, ...(selectedGuest.canRespondFor || [])].includes(guest.id)
    )
    .sort((a, b) => (a.id === selectedGuest.id ? -1 : b.id === selectedGuest.id ? 1 : 0));

  return (
    <div tw="mx-auto max-w-4xl">
      <Grid container spacing={1} sx={{ flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Flex container for names and buttons */}
        <Grid container xs={12} md={6} >
          <Grid item xs={12} >
            <h2>Hiya, {selectedGuest.name}!</h2>
            <Typography sx={{ color: 'white', fontWeight: 'bold', mb: 2 }}>
                  RSVP to thanksGLiving here!
            </Typography>
          </Grid>

          {managedGuests.map((guest) => (
            <Grid
              container
              item
              xs={12}
              key={guest.id}
              alignItems="center" // Ensures vertical alignment
              spacing={5} // Add space between items within row
            >
              {/* Guest Names */}
              <Grid 
                item 
                xs={6} // Left column takes half the row
                sx={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'white' }}>
                  {guest.name} {guest.nickname ? `(${guest.nickname})` : ''}
                </Typography>
              </Grid>

              {/* Response Buttons */}
              <Grid 
                item 
                xs={6} // Right column takes the other half
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {console.log(guest.response)} {/* Debugging line */}
                <ToggleButtonGroup
                value={guest.response || 'unknown'} // Fallback to 'unknown' if response is undefined
                  exclusive
                  onChange={(e,newResponse) => onRSVPChange(guest.id, newResponse)}
                  variant="outlined"
                >
                  <Button value="yes">
                    Yes
                  </Button>
                  <Button value="no">
                    No
                  </Button>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} >
                <h2>Gobble gobble!</h2>
                <Typography sx={{ color: 'white' }}>
                  The turkey's on the way & the cafe's secured.<br/> Please BYOB & to help with costs, may you please send Georgie £16pp for the evening! :)<br/>
                  Name: Georgina Ward<br/>
                  Sort code: 07-08-06<br/>
                  Account number: 09454737<br/>
                </Typography>
              </Grid>
        </Grid>

          {/* COLUMN for Event details */}
        <Grid item xs={12} md={4}>
          <h2>The when & where</h2>
          <Typography sx={{ color: 'white' }}>
            Saturday, 23 November, 2024<br />
            5:30pm<br /><br/>
              St Clements Cafe, 201 New Kings Road<br/><i>(~5mins from Parsons Green station)</i>
          </Typography>
          <a href="https://maps.app.goo.gl/F8SswtUFx1jxULYQ8" target='_blank'>
          <RSVPButton>Google maps</RSVPButton>
          </a>
        </Grid>
      </Grid>

      {/* Success Alert */}
      <Snackbar
        variant="soft"
        color="success"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}

        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        autoHideDuration={4000} // Duration before the alert automatically hides
      >
          {alertMessage}
      </Snackbar>
    </div>
  )
};

export default RSVPform;