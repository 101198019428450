// src/Home.js

import React from 'react';
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview";
import RSVP from "components/forms/RSVPcontainer";
import TabGrid from "components/cards/TabCardGrid.js";
import tw from "twin.macro";

import fpe1 from "images/fpe1.jpg";
import fpe4 from "images/fpe4.jpeg";
import fpe5 from "images/fpe5.jpeg";
import fpGeo from "images/fpGeo.jpeg";

const Home = () => {
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  return (
    <>
      <Hero />
      <RSVP />
      <Features />
      <MainFeature />
      <Testimonial 
          testimonials={[
            {
              imageSrc:
                fpe1,
              profileImageSrc:
                "https://scontent-lhr8-2.xx.fbcdn.net/v/t1.6435-9/69737972_1302069739960382_7584498841248858112_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=aVYBp1TzhzMQ7kNvgFGGgcR&_nc_zt=23&_nc_ht=scontent-lhr8-2.xx&_nc_gid=AzKzNGW66KJIMCZylwKjcaP&oh=00_AYBPzPOt5ImqBl0MXO2BmpPcoacxXEhtZOmdufEJb3MwJQ&oe=674E4011",
              quote:
                "Thanksgiving is the best. I love the turkey, the friends, and most importantly, the caesar salad. I can't wait to return to this exclusive event next year.",
              customerName: "Walker",
              customerTitle: "Chief Salad Officer"
            },
            {
              imageSrc:
                fpe4,
              profileImageSrc:
                "https://www.twall.co.uk/assets/stock/usedcar/KK19FXH/KK19FXH430855.jpg",
              quote:
                "I'm so glad they've moved to London.",
              customerName: "Yarold",
              customerTitle: "Toyota Yaris"
            },
            {
              imageSrc:
                fpe5,
              profileImageSrc:
                fpGeo,
              quote:
                "Peel more potatoes. That won't make enough mash.",
              customerName: "Georgie",
              customerTitle: "Chef"
            }
          ]}
          textOnLeft={true}
    />
      <TabGrid 
        heading={
          <>
            Checkout our <HighlightedText>2024 menu.</HighlightedText>
          </>
        }
        tabs={{
          Mains: [
            {
              imageSrc:
                "https://www.thespruceeats.com/thmb/nMCTtApdx_-RAJGksg4Cv1EnGjE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/perfect-roast-turkey-2216626-hero-01-37a7f1e9e51742998dac5956952f7d9f.jpg",
              title: "Turkey",
              content: "Perfectly basted and layed with bacon",
              price: "$5.99",
              rating: "4.9",
              reviews: "84",
              url: "#"
            },
            {
              imageSrc:
                "https://images.getrecipekit.com/20221207215747-screen-20shot-202022-12-07-20at-203.54?width=650&quality=90&",
              title: "Honey baked ham",
              content: "Sweet like honey, baked like ham",
              price: "$5.99",
              rating: "5.0",
              reviews: "87",
              url: "#"
            },
            {
                imageSrc:
                  "https://www.allrecipes.com/thmb/y2LUL3KI906mxcgj_GTAhqzU7kE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/ALR-recipe-13478-sweet-potato-casserole-ii-hero-04-VAT-4x3-49aaf773eaf34dbeb9be808ad74d41eb.jpg",
                title: "Sweet Potato & Marshmellios",
                content: "An american classic",
                price: "$2.99",
                rating: "4.8",
                reviews: "32",
                url: "#"
              },
              {
                imageSrc:
                  "https://www.southernliving.com/thmb/9hYMYfZY2XWB7NCcFLOMZmLVh4Q=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Best-Ever-MacaroniCheese-4x3-0282_preview_scale_100_ppi_150_quality_100-74d18d81a72644fcb7eb274bbe72eac3.jpg",
                title: "Mac N Cheese",
                content: "Because who doesn't love cheese",
                price: "$7.99",
                rating: "4.9",
                reviews: "89",
                url: "#"
              },
              {
                imageSrc:
                  "https://www.budgetbytes.com/wp-content/uploads/2023/11/Green-Bean-Casserole-Overhead-Close.jpeg",
                title: "Green Bean Casserole",
                content: "There are vegetables in this",
                price: "$8.99",
                rating: "4.6",
                reviews: "12",
                url: "#"
              },
              {
                imageSrc:
                  "https://ketocookingchristian.com/wp-content/uploads/2022/12/Honey-Roasted-Brussels-Sprouts1-scaled.jpeg",
                title: "Roasted yummy brussels",
                content: "Nurtured with honey and balsamic",
                price: "$7.99",
                rating: "4.2",
                reviews: "19",
                url: "#"
              },
              {
                imageSrc:
                  "https://www.allrecipes.com/thmb/SyYjkBhJ93Gmi_85hOLf8nUssuA=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/17891-golden-sweet-cornbread-ddmfs-beauty-4x3-BG-25990-bcabebac0323419abdf0497ee3383003.jpg",
                title: "Cornbread",
                content: "A southern classic",
                price: "$7.99",
                rating: "4.7",
                reviews: "37",
                url: "#"
              },
              {
                imageSrc:
                  "https://static01.nyt.com/images/2015/10/26/dining/26COOKING_BISCUITS1/26COOKING_BISCUITS1-superJumbo.jpg",
                title: "Parker house rolls",
                content: "An ideal sponge for your gravy",
                price: "$7.99",
                rating: "4.4",
                reviews: "22",
                url: "#"
              },
              {
                imageSrc:
                  "https://whatagirleats.com/wp-content/uploads/2023/10/horizontal-cabbage-hero-600x400.jpg",
                title: "Braised red cabbage",
                content: "A pop of colour on your plate",
                price: "$2.99",
                rating: "5.0",
                reviews: "61",
                url: "#"
              },
              {
                imageSrc:
                  "https://southernfoodandfun.com/wp-content/uploads/2023/11/southern-mashed-potatoes-feature-500x375.jpg",
                title: "Mash",
                content: "Butter, served with potatoes",
                price: "$3.99",
                rating: "4.2",
                reviews: "95",
                url: "#"
              },
              {
                imageSrc:
                  "https://www.eatyourselfskinny.com/wp-content/uploads/2023/11/sausage-stuffing-2-scaled.jpg",
                title: "Stuffing",
                content: "Perfect to fill any gaps",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: "#"
              },
              {
                imageSrc:
                  "https://hips.hearstapps.com/hmg-prod/images/romaine-lemon-vinaigrette-thanksgiving-salad-1629499336.jpg?crop=1xw:1xh;center,top&resize=980:*",
                title: "Romaine salad",
                content: "Nothing on Walker's caesar but it'll do",
                price: "$3.99",
                rating: "4.2",
                reviews: "24",
                url: "#"
              }
            ],
            Desserts: [
              {
                imageSrc:
                  "https://www.allrecipes.com/thmb/6pmFbaebCf8lDhCgGk6375QJ14Q=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/ar-12682-apple-pie-by-grandma-ople-ddmfs-beauty-1-069-326ca495fffb42588293e3d9c9391f00.jpg",
                title: "Apple pie",
                content: "One of your five a day!",
                price: "$5.99",
                rating: "5.0",
                reviews: "64",
                url: "#"
              },
              {
                imageSrc:
                  "https://img.sndimg.com/food/image/upload/q_92,fl_progressive,w_1200,c_scale/v1/img/recipes/10/19/54/FYx9Lmi6QLyORe9WIxDv_galetteIG.jpg",
                title: "Pecan pie",
                content: "The state pie of Texas, apparently",
                price: "$5.99",
                rating: "5.0",
                reviews: "78",
                url: "#"
              },
              {
                imageSrc:
                  "https://www.allrecipes.com/thmb/zs4c7gkhTG3yKwgNzr9wrVSz818=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/23439_PerfectPumpkinPie_001_Step4-f788a670b23544af848110c0800ebc8b.jpg",
                title: "Pumpkin pie",
                content: "Wouldn't be Thanksgiving without it",
                price: "$5.99",
                rating: "5.0",
                reviews: "87",
                url: "#"
              },
              {
                imageSrc:
                  "https://mykitchenescapades.com/wp-content/uploads/2021/11/5.jpg",
                title: "Crimble crumble",
                content: "You'd have thought 3 would do it",
                price: "$5.99",
                rating: "5.0",
                reviews: "82",
                url: "#"
              },
              {
                imageSrc:
                  "https://www.ifyougiveablondeakitchen.com/wp-content/uploads/2024/01/homemade-vanilla-ice-cream-recipe-500x375.jpg",
                title: "Homemade vanilla ice cream",
                content: "A signature dish to finish off the evening",
                price: "$5.99",
                rating: "5.0",
                reviews: "76",
                url: "#"
              },
          ]
        }}
      />
    </>
  );
};

export default Home;
