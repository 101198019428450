import React, { useState } from 'react';
import tw from 'twin.macro';

const RSVPButton = tw.button`w-full max-w-xs py-4 px-8 mt-6 bg-primary-903 text-white rounded-full font-bold tracking-wide shadow-lg text-lg transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-orange-600 hocus:-translate-y-px hocus:shadow-xl`;

const AmbigNameButton = tw.button`w-full py-4 px-8 mt-6 bg-primary-903 text-white rounded-full font-bold tracking-wide shadow-lg text-lg transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-orange-600 hocus:-translate-y-px hocus:shadow-xl`;

const NameInput = tw.input`
  appearance-none bg-transparent border-none 
  w-full max-w-md text-black py-2 px-4 
  text-4xl font-bold leading-tight 
  focus:outline-none text-center
`;

const Title = tw.h4`text-3xl font-bold text-gray-900`;


const RSVPname = ({ validGuests, onNameSubmit, isNameAmbiguous, handleGuestSelect }) => {
  const [isEnteringName, setIsEnteringName] = useState(false);
  const [name, setName] = useState('');

  const handleSubmit = () => {
    if (name.trim()) {
      onNameSubmit(name);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (/\s/.test(inputValue)) {
      //setError('Spaces are not allowed');
    } else {
      //setError('');
    }
    setName(inputValue.replace(/\s+/g, ''));
  };

  return (
    <div tw="min-h-screen flex flex-col items-center justify-center text-center">
      {!isEnteringName ? (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <RSVPButton onClick={() => setIsEnteringName(true)}>RSVP here!</RSVPButton>
        </span>
      ) : isNameAmbiguous ? (
        // Show the guest options if name is ambiguous
        <div tw="flex flex-col items-center space-y-4 mt-6">
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Title>Which {name}?</Title>
          </span>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div tw="flex flex-wrap justify-center mt-4">
              {validGuests.filter(guest => guest.name.toLowerCase() === name.toLowerCase()).map((guest, index) => (
                <span key={guest.id}>
                  <AmbigNameButton onClick={() => handleGuestSelect(guest)}>
                    {guest.nickname || guest.name}
                  </AmbigNameButton>
                </span>
              ))}
            </div>
          </span>
        </div>
      ) : (
        // Name input and continue button
        <div tw="flex flex-col items-center justify-center w-full max-w-md mx-auto space-y-4">
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <NameInput
              type="text"
              placeholder="firstname"
              value={name}
              onChange={handleInputChange}
              />
          </span>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <RSVPButton onClick={handleSubmit}>Continue</RSVPButton>
          </span>
        </div>
      )}
    </div>
  );
};



export default RSVPname;
