import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import fp2019 from "images/fp2019.jpg";
import fp2020 from "images/fp2020.jpg";
import fp2021 from "images/fp2021.jpeg";
import fp2022 from "images/fp2022.jpg";
import fp2023 from "images/fp2023.jpeg";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        fp2019,
      subtitle: "Whitehorn, St Andrews",
      title: "2019",
      description:
        `The thanksgiving that started it all! Lauren and I began the day cooking in the Wardlaw ground floor
kitchen and moved over to the lovely modern Whitehorn kitchen halfway through the day. Lauren and
I listened to One Direction all day, which is a tradition that has continued through the years. The
bacon wrapped green beans were a let-down, and a bit of a faff, but the rest of the food was not a bad
way to start. I think Andrew maybe concussed himself doing the dishes, but other than that, the day
was wonderful and a great start to a yearly tradition!`,
      url: "/years?y=1"
    },

    {
      imageSrc:
        fp2020,
      subtitle: "Drumcarrow Rd, St Andrews",
      title: "2020",
      description:
        `I’m so glad Lauren and I decided to go through with Thanksgiving despite the global
pandemic—though my goodness, what an effort it was! Online ordering forms, Tailend takeaway
boxes, and enough gravy to sink a ship (because we forgot to give it to everyone!). I think Walker
probably still has nightmares about packing everyone’s orders on the floor of the Drumcarrow living
room—each slightly different—while racing the clock as the boxes grew increasingly soggy. Despite
the grand effort, and the not-so-classic Thanksgiving, it was worth keeping the tradition alive. RIP, 2
Drumcarrow Rd!`,
      url: "/years?y=2"
    },

    {
      imageSrc:
        fp2021,
      subtitle: "Hepburn Hall, St Andrews",
      title: "2021",
      description:
        `This was our first “large” Thanksgiving dinner, and I’m still amazed we managed to fit everyone
around ONE long table in the living room of Hepburn. I made enough mashed potatoes to sink a large
cruise ship, even with Lauren persuading me to save one bag for another day. At the end of the
evening, when Lauren and I were packing all the food into Tupperware, we realized I’d made so much
mash that I hadn’t even gotten close to the bottom of the pot—the potatoes there were still completely
whole! This Thanksgiving also had the added bonus of St Andrews Day Celebrations. Once we were
all stuffed to the brim, we walked into town for the St Andrews Day ceilidh and parade. Lauren and I
ran into the Whey Pat on the way for two shots each, and we ceilidh-ed down South Street! A
wonderful way to end the eve.`,
      url: "/years?y=3"
    },

    {
      imageSrc:
        fp2022,
      subtitle: "Hepburn Hall, St Andrews",
      title: "2022",
      description:
        `The largest Thanksgiving of them all, with around 40 guests (including my childhood best friend Molly, who travelled all the way from Atlanta, Georgia). This Thanksgiving had all the classics, and it was the first year I made a honey-baked ham and added a berry crumble. James and Charlie managed to build a couple of “bonfires” on the table to add to the ambiance, which was followed by a successful quiz made by James.`,
      url: "/years?y=4"
    },

    {
      imageSrc:
        fp2023,
      subtitle: "Parsons Green, London",
      title: "2023",
      description:
        `Not quite as grand as Hepburn, but a success nonetheless. The day started with me wondering why
my pies were burning instantly… little did I know the oven was broken, and we’d have to cook the
entire Thanksgiving meal under a grill. I still have no idea how Lauren and I managed to cook an
entire turkey without a functional oven, but somehow we only served dinner a little over 30 minutes
late. The pies were yummy but slightly raw on the bottom due to the oven issues, yet spirits stayed
high! Because our Radipole Road flat is quite petite, I could only invite 12 people, but it was so lovely
to have both old and new friends, from my St Andrews family to friends I met on my MSc course. This
one was chaotic, but the food went down with no complaints!`,
      url: "/years?y=5"
    }
  ];

  /*
  <HeadingDescription>
  Here are some of the most popular events in New York City curated by professionals.
</HeadingDescription>
  */

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Over the years...</HeadingTitle>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>Look back on {card.title}</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
